import { createAppSlice } from "./createAppSlice";

const initialState = {
    links: [],
};

export const inTheNewsSlice = createAppSlice({
    name: "inTheNews",
    initialState,
    reducers: {
        setLinks: (state, action) => {
            state.links = action.payload;
        },
    },
    selectors: {
        selectLinks: state => {
            return state.links;
        },
    },
});

export const { setLinks } = inTheNewsSlice.actions;

export const { selectLinks } = inTheNewsSlice.selectors;
