import { createAppSlice } from "./createAppSlice";

const initialState = {
    faqCategories: [],
};

export const categoriesSlice = createAppSlice({
    name: "categories",
    initialState,
    reducers: {
        setFaqCategories: (state, action) => {
            state.faqCategories = action.payload;
        },
    },
    selectors: {
        selectFaqCategories: state => state.faqCategories,
    },
});

export const { setFaqCategories } = categoriesSlice.actions;

export const { selectFaqCategories } = categoriesSlice.selectors;
