import { createAppSlice } from "./createAppSlice";

const initialState = {
    monthlyEvents: [],
};

export const monthlyEventsSlice = createAppSlice({
    name: "monthlyEvents",
    initialState,
    reducers: {
        setMonthlyEvents: (state, action) => {
            state.monthlyEvents = action.payload;
        },
    },
    selectors: {
        selectMonthlyEvents: state => {
            return state.monthlyEvents;
        },
    },
});

export const { setMonthlyEvents } = monthlyEventsSlice.actions;

export const { selectMonthlyEvents } = monthlyEventsSlice.selectors;
