import { createSelector } from "@reduxjs/toolkit";
import { createAppSlice } from "./createAppSlice";

const initialState = {
    topics: [],
    pressReleases: [],
};

export const articleSlice = createAppSlice({
    name: "article",
    initialState,
    reducers: {
        setBlogTopics: (state, action) => {
            state.topics = action.payload;
        },
        setPressReleases: (state, action) => {
            state.pressReleases = action.payload;
        },
    },
    selectors: {
        //Memoize selector to avoid expensive re-calculations
        selectBlogTopics: createSelector(
            [state => state.topics, (_, type) => type],
            (topics, type = "Blog Post") => {
                return topics?.filter(({ article }) =>
                    article?.some(a => a.type === type)
                );
            }
        ),
        selectPressReleases: state => state.pressReleases,
    },
});

export const { setBlogTopics, setPressReleases } = articleSlice.actions;

export const { selectBlogTopics, selectPressReleases } = articleSlice.selectors;
